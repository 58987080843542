import React from "react";
import {
  Box,
  Card,
  Typography,
  CardContent,
} from "@material-ui/core";

interface TournamentCardProps {
  type: string;
  logo?: string;
  name?: string;
}

export const TournamentCard = ({ type, logo, name }: TournamentCardProps) => {
  const formatTournamentName = (name: string = '') => {
    const mainName = name.split(' 20')[0];
    return mainName;
  };

  return (
    <Card elevation={3} style={{
      width: '100%',
      backgroundColor: '#fff',
      margin: '0 auto',
      maxWidth: '140px',
      overflow: 'hidden'
    }}>
      <CardContent style={{
        padding: '12px 0 12px 12px',
        height: '84px',
        display: 'flex',
        alignItems: 'center'
      }}>
        <Box
          display="flex"
          alignItems="center"
          style={{
            width: '100%',
            position: 'relative',
            gap: '8px'
          }}
        >
          <img
            src={logo}
            alt={`${type} logo`}
            style={{
              width: '52px',
              height: '52px',
              objectFit: 'contain',
              marginRight: '4px',
              flexShrink: 0
            }}
          />
          <Typography
            variant="h6"
            style={{
              fontWeight: 500,
              fontSize: '1.1rem',
              position: 'absolute',
              left: '52px',
              right: 0,
              whiteSpace: 'nowrap'
            }}
          >
            {formatTournamentName(name) || type}
          </Typography>
        </Box>
      </CardContent>
    </Card>
  );
};
