import React from "react";
import {
  Box,
  Card,
  CardActionArea,
  CardContent,
  Link,
  Typography,
} from "@material-ui/core";
import { INWIN_BENE_SIGNUP_URL } from "config";

export const BecomeBeneficiaryCard = () => {
  return (
    <Card style={{
      width: '300px',
      height: '140px',
      margin: '0 8px',
      boxShadow: '0px 3px 8px rgba(0, 0, 0, 0.35)'
    }}>
      <Link
        color={"inherit"}
        underline={"none"}
        target={"_blank"}
        rel={"noopener noreferrer"}
        href={INWIN_BENE_SIGNUP_URL}
        style={{ height: '100%', display: 'block' }}
      >
        <CardActionArea style={{ height: '100%' }}>
          <CardContent>
            <Box textAlign={"left"}>
              <Typography variant="body1">
                <strong>Apply to become a Beneficiary</strong>
              </Typography>
              <Typography variant={"body2"}>
                and start receiving donations!
              </Typography>
            </Box>
          </CardContent>
        </CardActionArea>
      </Link>
    </Card>
  );
};
