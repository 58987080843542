import React from "react";
import {
  Box,
  CardActionArea,
  CardMedia,
  Link,
  Typography,
} from "@material-ui/core";
import { useStyles } from "./styles";
import { IDefaultSponsorProps } from "./ISponsorCardProps";
import { INWIN_PUBLIC_RESOURCES } from "../../../../config";

export const DefaultSponsor = ({ sponsor }: IDefaultSponsorProps) => {
  const styles = useStyles();

  return (
    <Link
      color={"inherit"}
      underline={"none"}
      target={"_blank"}
      rel={"noopener noreferrer"}
      href={sponsor.website}
    >
      <CardActionArea className={styles.cardActionArea}>
        <Box display={"flex"}>
          {sponsor?.logo ? (
            <CardMedia
              className={styles.cardMediaSponsor}
              image={`${INWIN_PUBLIC_RESOURCES}${sponsor.logo}`}
            />
          ) : (
            <Box
              className={styles.cardMediaSponsor}
              display="flex"
              alignItems="center"
              justifyContent="center"
              bgcolor="#f5f5f5"
            >
              <Typography variant="body2">No Logo</Typography>
            </Box>
          )}
          <Box
            display={"flex"}
            alignItems={"center"}
            textAlign={"left"}
            paddingLeft={2}
          >
            <Box>
              <Typography variant="body1" noWrap>
                <strong>{sponsor?.fullName}</strong>
              </Typography>
            </Box>
          </Box>
        </Box>
      </CardActionArea>
    </Link>
  );
};
