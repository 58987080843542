import React from "react";
import { IBeneficiaryProps } from "./IBeneficiaryProps";
import { BecomeBeneficiaryCard } from "./becomeBeneficiary";
import { Card, CardContent, Box, Typography } from '@material-ui/core';

export const BeneficiaryCard = ({
  isPlaceholder,
  beneficiary = null,
}: IBeneficiaryProps) => {
  if (isPlaceholder) {
    return <BecomeBeneficiaryCard />;
  }

  if (!beneficiary) {
    return null;
  }

  const formatMoney = (amount: number | undefined) => {
    return `$${(amount || 0).toLocaleString()}`;
  };

  return (
    <Card style={{
      width: '300px',
      height: '140px',
      margin: '0 4px',
      boxShadow: '0px 3px 8px rgba(0, 0, 0, 0.35)'
    }}>
      <CardContent style={{
        padding: '12px',
        height: '100%'
      }}>
        <Box
          display="flex"
          alignItems="center"
          style={{
            gap: '16px',
            height: '100%'
          }}
        >
          <Box
            style={{
              backgroundColor: '#333333',
              display: 'inline-flex',
              alignItems: 'center',
              justifyContent: 'center'
            }}
          >
            <img
              src={beneficiary.logo}
              alt={`${beneficiary.beneficiaryName} logo`}
              style={{
                maxWidth: '120px',
                maxHeight: '120px',
                width: 'auto',
                height: 'auto',
                display: 'block'
              }}
            />
          </Box>

          <Box flex={1}>
            <Typography variant="body1" style={{ fontWeight: 700, marginBottom: '2px' }}>
              {beneficiary.beneficiaryName}
            </Typography>
            <Typography variant="body2" color="textSecondary" style={{ fontSize: '0.75rem', lineHeight: 1.2, fontWeight: 600 }}>
              Donated last week: {formatMoney(beneficiary.statistics?.inWin?.earnings?.LAST_WEEK)}
            </Typography>
            <Typography variant="body2" color="textSecondary" style={{ fontSize: '0.75rem', lineHeight: 1.2, fontWeight: 600 }}>
              Donated all time: {formatMoney(beneficiary.statistics?.inWin?.earnings?.ALL)}
            </Typography>
          </Box>
        </Box>
      </CardContent>
    </Card>
  );
};
