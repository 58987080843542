import React, { useEffect, useState } from "react";
import { RouteComponentProps } from "@reach/router";
import { Box, Grid, Typography, useMediaQuery, useTheme } from "@material-ui/core";
import * as api from "application/statistics.api";
import { useStyles } from "./styles";
import {
  SponsorCard,
  TournamentCard,
  BeneficiaryCard,
} from "../../shared/components";
import { AppsAndRegister } from "../main/apps";
import { IBeneficiaryData } from '../../shared/types/beneficiary';
import { ITournamentData } from '../../shared/types/tournament';

export const Beneficiaries = (props: RouteComponentProps) => {
  const styles = useStyles();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  const isNarrowScreen = useMediaQuery('(max-width:360px)');
  const [beneficiaries, setBeneficiaries] = useState<IBeneficiaryData[]>([]);
  const [sponsor, setSponsor] = useState({});
  const [tournaments, setTournaments] = useState<ITournamentData[]>([]);

  useEffect(() => {
    api.getStatisticBeneficiaries().then(setBeneficiaries);
    api.getStatisticSponsors().then(setSponsor);
    api.getActiveTournaments().then(setTournaments);
  }, []);

  return (
    <>
      <AppsAndRegister />
      <Box
        textAlign="center"
        className={styles.root}
        style={{
          width: '100%',
          maxWidth: '100%',
          overflowX: 'hidden',
          padding: isNarrowScreen ? '0 2px' : '0 4px',
          margin: '0 auto',
          minHeight: '100vh',
          display: 'flex',
          flexDirection: 'column'
        }}
      >
        <Grid container justify="center" alignItems="center" style={{ marginBottom: '48px', marginTop: '32px' }}>
          <Grid item xs={12} style={{ marginBottom: '8px' }}>
            <Typography variant={"h5"}>Live Tournaments</Typography>
          </Grid>
          <Box
            display="flex"
            justifyContent="center"
            flexDirection={isNarrowScreen ? 'column' : 'row'}
            style={{
              marginTop: '8px',
              gap: '8px',
              width: '100%',
              padding: isNarrowScreen ? '0' : '0 2px',
              maxWidth: isNarrowScreen ? '340px' : '600px',
              margin: '0 auto'
            }}
          >
            {tournaments.map((tournament) => (
              <Box
                key={tournament.id}
                style={{
                  flex: isNarrowScreen ? 'none' : '1',
                  maxWidth: isNarrowScreen ? '100%' : '140px',
                  width: isNarrowScreen ? '100%' : 'auto'
                }}
              >
                <TournamentCard
                  type={tournament.sportType}
                  logo={tournament.logo}
                  name={tournament.tournamentName}
                />
              </Box>
            ))}
          </Box>
        </Grid>
        <Grid container spacing={1} justify="center" alignItems="center" style={{ marginBottom: '48px' }}>
          <Grid item xs={12} style={{ marginBottom: '8px' }}>
            <Typography variant={"h5"}>Beneficiaries</Typography>
          </Grid>
          <Box
            display="flex"
            justifyContent="center"
            style={{
              gap: '1px',
              width: '100%',
              margin: '0 auto',
              padding: isNarrowScreen ? '0 4px' : '0 8px',
              flexWrap: (isMobile || isNarrowScreen) ? 'wrap' : 'wrap',
              maxWidth: '2560px',
              ...((isMobile || isNarrowScreen) && {
                padding: '0 4px',
                minWidth: 'fit-content',
                overflowX: 'hidden'
              })
            }}
          >
            <Box
              display="flex"
              style={{
                gap: '1px',
                flexWrap: (isMobile || isNarrowScreen) ? 'wrap' : 'wrap',
                ...((isMobile || isNarrowScreen) && {
                  width: '100%',
                  flexDirection: 'column',
                  alignItems: 'center',
                  gap: '8px'
                }),
                ...(!isMobile && !isNarrowScreen && {
                  width: '100%',
                  maxWidth: '2000px',
                  justifyContent: 'center'
                })
              }}
            >
              {beneficiaries.map((beneficiary, index) => (
                <BeneficiaryCard
                  key={beneficiary.id || index}
                  beneficiary={beneficiary}
                />
              ))}
              <BeneficiaryCard isPlaceholder beneficiary={null} />
            </Box>
          </Box>
        </Grid>
        <Grid container spacing={1} justify="center" alignItems="center" style={{ marginBottom: '48px' }}>
          <Grid item xs={12} style={{ marginBottom: '8px' }}>
            <Typography variant={"h5"}>Sponsors</Typography>
          </Grid>
          <SponsorCard sponsor={sponsor} isPlaceholder={false} />
          <SponsorCard sponsor={sponsor} isPlaceholder={true} />
        </Grid>
      </Box>
    </>
  );
};
