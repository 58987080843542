import axios from 'axios';
import { IBeneficiaryData } from '../view/shared/types/beneficiary';
import { ITournamentData } from '../view/shared/types/tournament';
import { INWIN_PUBLIC_RESOURCES } from '../config';

/**
 * Get info for Beneficiaries
 * 1. Fetch top 3 beneficiaries in descending order
 * 2. Transform logo URLs to include full public resources path
 * 3. Return transformed beneficiaries array
 */
export const getStatisticBeneficiaries = async (): Promise<IBeneficiaryData[]> => {
  try {
    const { data: beneficiaries } = await axios.get(
      "statistics/member/beneficiaries?contributionDays=30&page=0&size=3&sort=contribution,desc&sort=id,asc"
    );

    // Transform the data to include full logo URLs using the logo base URL
    const transformedBeneficiaries = beneficiaries.content.map((b: IBeneficiaryData) => {
      const fullLogoUrl = b.logo ? `${INWIN_PUBLIC_RESOURCES}${b.logo}` : '';

      return {
        ...b,
        logo: fullLogoUrl
      };
    });

    return transformedBeneficiaries;

  } catch (error) {
    console.error('Error in getStatisticBeneficiaries:', error);
    return [];
  }
};

/**
 * Get info for Tournaments by type
 * @param type "nba" | "nhl" | "mlb"
 */
export const getStatisticTournament = async (type: "nba" | "nhl" | "mlb") => {
  const matches = [
    axios.get(`statistics/matches/${type}`, {
      params: {
        seasonYear: "2024",
      },
    }),
    axios.get(`statistics/matches/${type}`),
  ];

  const matchesResponse = await Promise.all(matches);

  const formattedData = matchesResponse.map(({ data }) => data);
  return {
    seasonMatches: formattedData[0],
    allMatches: formattedData[1],
  };
};

/**
 * Get info for Sponsors
 * only first sponsor
 */
export const getStatisticSponsors = async () => {
  const { data } = await axios.get(`/sponsor/sponsors`);

  const firstSponsor = data.content[0];
  return {
    id: firstSponsor?.id,
    fullName: firstSponsor?.fullName,
    logo: firstSponsor?.logo,
    allTime: firstSponsor?.donatedAllTime,
    lastMonth: firstSponsor?.donatedLastMonth,
    lastYear: firstSponsor?.donatedLastYear,
    website: firstSponsor?.website,
  };
};

/**
 * Get active tournaments
 */
export const getActiveTournaments = async (): Promise<ITournamentData[]> => {
  try {
    const { data: tournaments } = await axios.get(
      "tournament/tournaments/search?page=0&size=3&includePassedMatches=false"
    );

    // Transform the data to include full logo URLs
    const transformedTournaments = tournaments.content.map((t: ITournamentData) => ({
      ...t,
      logo: t.logo ? `${INWIN_PUBLIC_RESOURCES}${t.logo}` : '',
      largeLogo: t.largeLogo ? `${INWIN_PUBLIC_RESOURCES}${t.largeLogo}` : ''
    }));

    return transformedTournaments;
  } catch (error) {
    console.error('Error in getActiveTournaments:', error);
    return [];
  }
};
